$(document).ready(function () {
  $('.print-label').on('click', function() {
    setInterval(function() {
      window.location.reload();
    }, 3000);
  });

  $('.js-confirm-destroy-label-print').on('click', function() {
    let labelPrintId = $(this).data('label-print-id');

    $.ajax({
      url: `/label_prints/${labelPrintId}`,
      method: 'DELETE',
      success: function (res) {
        if (res.status) {
          window.location.href = `/label_prints`;
        }
      },
    });
  });

  $('.js-confirm-destroy-all-label-print').on('click', function() {
    $.ajax({
      url: `/label_prints/delete_all`,
      method: 'DELETE',
      success: function (res) {
        if (res.status) {
          window.location.href = `/label_prints`;
        }
      },
    });
  });
})


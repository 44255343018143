// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()
import "bootstrap/dist/js/bootstrap"
import "bootstrap/dist/css/bootstrap"
import "../stylesheets/application"
import "./pages/label_prints.js"

import * as bootstrap from 'bootstrap'

import JQuery from 'jquery'
window.$ = window.JQuery = JQuery
